// noinspection ES6UnusedImports

import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import { ApiStatus, updateApiStatus } from 'src/app/interfaces/api-status'
import { environment } from 'src/environments/environment'
import { CombinedStateModel } from '.'
import {
  GetHelpEnrichment,
  HandleHelpEnrichmentResponse,
  SwitchHelp,
  ToggleHelpIsActive,
} from '../actions/content.actions'
import { normalizeHelpContent } from './normalizers/content.normalizer'
import {
  HelpEnrichment,
  HelpEnrichmentData,
} from 'src/app/models/audit-enrichment'
import { HelpObject, HelpSystem } from 'src/app/interfaces/content'

export interface HelpStateModel extends ApiStatus {
  nl: HelpEnrichmentData[] | null
  en: HelpEnrichmentData[] | null
  selectedHelp: HelpObject[]
  nonregularHelp: HelpObject[]
  uploadHelp: HelpObject[]
  visaHelp: HelpObject[]
  isActive: boolean
  autoPopup: any | null
  popupDismissable: boolean
}

export const defaultState: HelpStateModel = {
  nl: null,
  en: null,
  hasApiError: false,
  hasConnectionError: false,
  isFetching: false,
  isInitialised: false,
  selectedHelp: null,
  nonregularHelp: null,
  uploadHelp: null,
  visaHelp: null,
  isActive: false,
  autoPopup: null,
  popupDismissable: true,
}

@State<HelpStateModel>({
  name: 'help',
  defaults: { ...defaultState },
})
@Injectable()
export class HelpState {
  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store
  ) {}
  private headers: HttpHeaders = new HttpHeaders({
    apiKey: environment.apiKey,
  })

  @Selector()
  static selectedHelpSystem({
    selectedHelp,
    nonregularHelp,
    uploadHelp,
    visaHelp,
    autoPopup,
    popupDismissable,
    isActive,
  }: HelpStateModel): HelpSystem {
    return { selectedHelp, nonregularHelp, uploadHelp, visaHelp, autoPopup, popupDismissable, isActive }
  }

  private static constructUrl(languageCode: string): string {
    return `${environment.endpoints.root}${environment.endpoints.help}?lang=${languageCode}`
  }

  @Action(ToggleHelpIsActive)
  async toggleHelpIsActive(ctx: StateContext<HelpStateModel>) {
    ctx.setState((oldState) => ({
      ...oldState,
      isActive: !oldState.isActive,
    }))
  }

  @Action(SwitchHelp)
  async switchHelp(ctx: StateContext<HelpStateModel>) {
    const { participant } = this.store.snapshot() as CombinedStateModel
    const { languageCode } = participant
    const translations = ctx.getState()[languageCode]?.map(normalizeHelpContent)
    if(translations) {
      const menuHelp = translations.filter((el) => el.title === 'Help')
      const nonregularHelp = translations.filter((el) => el.title === 'Nonregular applications')
      const uploadHelp = translations.filter((el) => el.title === 'Upload documents')
      const visaHelp = translations.filter((el) => el.title === 'Upload visa documents')
      ctx.patchState({
        selectedHelp: menuHelp,
        nonregularHelp: nonregularHelp,
        uploadHelp: uploadHelp,
        visaHelp: visaHelp
      })
    } else{
      return ctx.dispatch(new GetHelpEnrichment())
    }
  }

  @Action(GetHelpEnrichment)
  async GetHelpEnrichment(ctx: StateContext<HelpStateModel>): Promise<void> {
    const { participant } = this.store.snapshot() as CombinedStateModel
    const { languageCode } = participant
    ctx.patchState({ ...updateApiStatus({ isFetching: true }) })
    this.http
      .get<HelpEnrichment>(HelpState.constructUrl(languageCode), {
        headers: this.headers,
      })
      .subscribe({
        next: (data) => ctx.dispatch(new HandleHelpEnrichmentResponse(data)),
        error: (e) => console.warn(e),
      })
  }

  @Action(HandleHelpEnrichmentResponse)
  handleHelpEnrichmentResponse(
    ctx: StateContext<HelpStateModel>,
    { enrichment }: HandleHelpEnrichmentResponse
  ) {
    const { participant } = this.store.snapshot() as CombinedStateModel
    const { languageCode } = participant
    const popupTxt = (popup) =>
      popup.length > 0 ? popup[0].attributes.field_text.processed : ''
    let dismissable = true
    let popup = enrichment.data.filter(
      (el) => el.attributes.title === '_popupUndismissable'
    )
    if (popup && popupTxt(popup).length > 8) {
      dismissable = false
    } else {
      popup = enrichment.data.filter(
        (el) => el.attributes.title === '_popupDismissable'
      )
    }

    ctx.setState((oldState) => ({
      ...oldState,
      ...updateApiStatus({ isInitialised: true }),
      [languageCode]: enrichment.data,
      autoPopup:
        popupTxt(popup).length > 8
          ? [{ title: 'popup', content: popupTxt(popup) }]
          : null,
      popupDismissable: dismissable,
      isActive: popupTxt(popup).length > 8,
    }))
    ctx.dispatch(new SwitchHelp())
  }
}
