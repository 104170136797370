import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { CanActivateProtectedRouteGuard } from './guards/can-activate-protected-route.guard'
import { CanActivatePublicRouteGuard } from './guards/can-activate-public-route.guard'

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'auth',
        // canActivate: [CanActivatePublicRouteGuard],
        loadChildren: () =>
          import('./modules/auth/auth.page.module').then(
            (m) => m.AuthPageModule
          ),
      },
      {
        path: 'login',
        canActivate: [CanActivatePublicRouteGuard],
        loadChildren: () =>
          import('./modules/login/login.page.module').then(
            (m) => m.LoginPageModule
          ),
      },
      {
        path: 'no-admission',
        canActivate: [CanActivateProtectedRouteGuard],
        loadChildren: () =>
          import('./modules/no-admission/no-admission.page.module').then(
            (m) => m.NoAdmissionPageModule
          ),
      },
      {
        path: 'home',
        canActivate: [CanActivateProtectedRouteGuard],
        loadChildren: () =>
          import('./modules/home/home.page.module').then(
            (m) => m.HomePageModule
          ),
      },
      {
        path: 'logout',
        loadChildren: () =>
          import('./modules/logout/logout.page.module').then(
            (m) => m.LogoutPageModule
          ),
      },
      {
        path: 'feedback',
        canActivate: [CanActivateProtectedRouteGuard],
        loadChildren: () =>
          import('./modules/feedback/feedback.page.module').then(
            (m) => m.FeedbackPageModule
          ),
      },
      {
        path: 'analytics',
        canActivate: [CanActivatePublicRouteGuard],
        loadChildren: () =>
          import('./modules/analytics/analytics.page.module').then(
            (m) => m.AnalyticsPageModule
          ),
      },
      {
        path: 'myum',
        canActivate: [CanActivateProtectedRouteGuard],
        loadChildren: () =>
          import('./modules/myum/myum.page.module').then(
            (m) => m.MyUmPageModule
          ),
      },
      {
        path: 'visa',
        canActivate: [CanActivateProtectedRouteGuard],
        loadChildren: () =>
          import('./modules/myum/myum.page.module').then(
            (m) => m.MyUmPageModule
          ),
      },
      {
        path: '**',
        redirectTo: 'login',
      },

    ],
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
