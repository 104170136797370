export const environment = {
  name: 'production',
  maintenance: false,
  production: true,
  release: '1.3.1',
  authServer: {
    login:
      'https://simplesso.maastrichtuniversity.nl/IdentityProviderSelection',
    token: 'https://simplesso.maastrichtuniversity.nl/api/Identity',
    apiKey: '86ad4c77-3db3-4f37-861b-7e6ac178b98c',

  },
  apiKey: '86ad4c77-3db3-4f37-861b-7e6ac178b98c',
  callbackUrl: 'https://myapplication.maastrichtuniversity.nl/auth',
  sentry: {
    dsn: 'https://e646e053b40943669273afa03e7ae098@o1400545.ingest.sentry.io/6729681',
    tracingOrigins: ['intg.maastrichtuniversity.nl'],
  },
  endpoints: {
    root: 'https://intg.maastrichtuniversity.nl/AJ_BFF/v10/',
    student: 'api/Student',
    admissions: 'Admission',
    audits: `Admission`,
    auditDocuments: 'Documents',
    auditDocumentsUpload: 'Documents/Upload',
    enrichment: 'AuditEnrichment',
    statusTexts: 'api/AuditStatusText',
    help: 'api/drupalenrichment/GetHelpEnrichment',
    menu: 'api/drupalenrichment/GetMenuBarEnrichment',
    message: 'MessageBox',
    messageStatus: 'Status',
    chat_en: 'https://www.maastrichtuniversity.nl/chat.html',
    chat_nl: 'https://www.maastrichtuniversity.nl/chat-nl.html',
    webdynProRegistration: "https://scsp.myum.unimaas.nl/sap/bc/webdynpro/sap/zpiq_nr_admission_saml#",
    webdynProUpload: "https://scsp.myum.unimaas.nl/sap/bc/webdynpro/sap/zadm_wd_znaw_upload_saml#",
    webdynProVisaUpload: "https://scsp.myum.unimaas.nl/sap/bc/webdynpro/sap/zpiq_visa_upload_saml#"
  },
  contactEmail: 'study@maastrichtuniversity.nl',
  serviceDeskPage: 'https://www.maastrichtuniversity.nl/about-um/service-centres/ict-service-centre',
  nodeDocTypes: {
    portraitPicture: '098',
  },
  linkTypes: ['A', 'I', 'P', 'Q', 'W', 'H'],
  subRequirements: {
    hidden: [],
    welcome: '10002802',
    portraitPhoto: '10002769',
    handlingFee: '10002700',
    exemptionForm: '10002701',
    englishLanguageProficiency: '10002718',
  },
  allowedFileExtentions: {
    default: ['jpg', 'jpeg', 'pdf', 'png', 'doc', 'docx', 'csv', 'xls', 'xlsx'],
    portraitPhoto: ['jpg', 'jpeg'],
  },
  featureFlags: {
    available: ['decision-tree-en-req'],
    enabled: [],
  },
  polling: {
    getMessages: 60000,
    getDocuments: 60000,
  },
  throttle: {
    getMessages: 2000,
    getDocuments: 2000,
    logEvent: 2000,
  },
  sessionExpiration: {
    idleTime: 1500,
    timoutTime: 300,
    keepAliveInterval: 900,
  },
  surveyCode : 'feedback2024-sep',
  datahubApi: {
    url: 'https:///myapplication.maastrichtuniversity.nl/datahub/api/',
    authorizationKey:
      '891e0c50548851333682b5baa8a725d3861197c21e43e9478f682aec3c0a9faf20c4d69bac7779b5703b03aaab011e60c86f32ab0514ba6c73d711e9bcb155b2200cde3c80271fffb688118aa23ff019d4061f1f9ca4f72112a9c643453ae37893d6303c424f90089191a6d3af0a3025ef4cbd42e0af639283f4e6533e97f2fa',
  },
  contenthubApi: {
    url: 'https:///myapplication.maastrichtuniversity.nl/api/',
    authorizationKey:
      '69025c10-fa8a-4464-a961-2061b6dd3c08',
  },
  contenthubJsonApi: {
    url: 'https:///myapplication.maastrichtuniversity.nl/jsonapi/',
  },
}
